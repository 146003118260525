export default function () {
    const parentsArticle = [
        {
            month: 1,
            activities: [
                {
                    imgUrl: require('@/assets/activity/parents-1-1.jpg'),
                    title: '新理年 真出発発展式',
                    article:
                        '元旦0時より行わせて頂く式典です。『大親神大生命』のお力・お働きにより新しい一年を迎えさせて頂いた、大いなる親感謝と新たな決意を込めて行わせて頂きます。',
                },
                {
                    imgUrl: require('@/assets/activity/parents-1-2.jpg'),
                    title: '大親真体系 大自然界 親感謝祭',
                    article:
                        '『大親神大生命』は私たち生命の御子を生かし育てて下さる為に、「大親真体系　大自然界」という素晴らしい世界をお創り下さり、その全ての営みを行っていて下さいます。全ての生命の御子が「大親真体系　大自然界」に活かされ生きている親感謝と祈りを、『大親神大生命』に対し奉り捧げさせて頂きます。',
                },
            ],
        },
        {
            month: 3,
            activities: [
                {
                    imgUrl: require('@/assets/activity/parents-3.jpg'),
                    title: '親生命界 親感謝祭',
                    article:
                        '『大親神大生命』がお創り下さった生命の理の世界を「親生命界」と言います。\n \
                        『大親神大生命』の大いなる生命のお力・お働きによって、私たち全生物は活かされ生きております。生命の理が無ければ、全生物は生きる事が出来ません。全生物は、『大親神大生命』の大いなる生命の一部をお与え頂いて、「生命の御子」として生存させて頂き、様々な生きる味わいと喜びを収穫させて頂いております。\n \
                        「親生命界　親感謝祭」は、『大親神大生命』の「生命の理」に対し奉り、親感謝と生存の喜びの祈りを捧げさせて頂く行事です。',
                },
            ],
        },
        {
            month: 6,
            activities: [
                {
                    imgUrl: require('@/assets/activity/parents-6.jpg'),
                    title: '親命霊界 親感謝祭',
                    article:
                        '『大親神大生命』がお創り下さった気持ちの理の世界を「親命霊界」と言います。\n \
                        『大親神大生命』は大親の親生命霊（御心、お気持ち）によって、大自然界をお創り下さり、全生物を生かして下さっておられます。\n \
                        『大親神大生命』の貴き親生命霊の理の一部を「生命霊（魂・心・気持ち）」としてお与え頂いているので、私たちは喜怒哀楽など様々な感情・情緒を感じ、味わう事が出来るのです。\n \
                        「親命霊界　親感謝祭」では、『大親神大生命』に対し奉り「気持ちの理」の大いなる親感謝を捧げさせて頂きます。',
                },
            ],
        },
        {
            month: 9,
            activities: [
                {
                    imgUrl: require('@/assets/activity/parents-9.jpg'),
                    title: '親返礼祭',
                    article:
                        'この一年間で肉体的死を迎えた全生物を代表して、この世＝「親物質現象界」で使わせて頂いた肉体（遺灰）を、生命の大親である『大親神大生命』に対し奉り親感謝を込めてお返しさせて頂く親祭典です。\n \
                        私たちを含め全生物が『大親神大生命』より肉体をお貸し与え頂いて、この世に活かされ生きて、様々な体験・経験の味わいを生命霊（魂・心）に収穫させて頂いております。人間同士ですと借りた物を返す時「ありがとう」と言って返します。しかしこの地球上では今まで死ぬ時＝肉体をお返しさせて頂く時に『大親神大生命』に対し奉り親感謝の御礼をした事がありませんでした。\n \
                        「親返礼祭」ではこの地球上に活かされ生きた全ての生物を代表し、『大親神大生命』に対し奉り、この一生のあいだ体を使わせて頂いた親感謝を行わせて頂きます。',
                },
            ],
        },
        {
            month: 11,
            activities: [
                {
                    imgUrl: require('@/assets/activity/parents-11.jpg'),
                    title: '親物質現象界 親感謝祭',
                    article:
                        '『大親神大生命』がお創り下さった、物質・肉体の理の世界を「親物質現象界」と言います。「親物質現象界」の事を、人間は「この世」と呼んでいます。「親物質現象界」は、時間・空間の変化の味わいある素晴らしい親世界です。\n \
                        『大親神大生命』より肉体をお貸し与え頂いて、私たちはこの世＝「親物質現象界」に誕生させて頂き、肉体を使った一生を通して、生存の様々な喜び味わいを自分の生命霊（魂・心）に収穫させて頂いております。「親物質現象界」が無ければ、何も材料が無いので、何の気持ちも味わう事が出来ません。\n \
                        『大親神大生命』に対し奉り、この変化する素晴らしい世界「親物質現象界」に活かされ生きる親感謝を、真心を込めて行わせて頂きます。',
                },
            ],
        },
        {
            month: 12,
            title: '最終日曜日',
            activities: [
                {
                    imgUrl: require('@/assets/activity/parents-12.jpg'),
                    title: '親感謝 真修祭',
                    article:
                        '『大親神大生命』に対し奉り、貴き親理のお授けと御親育を頂いた一年間の大いなる親感謝と感動の実感を込めまして行わせて頂く親祭典です。\n \
                        一年の素晴らしい日々を思い起こし、自分たちを含む全生物がこの一年間も活かされ生きて沢山の生きる味わいをさせて頂きました事を、地球を代表して親感謝させて頂きます。',
                },
            ],
        },
    ]

    return { parentsArticle }
}
