<template>
    <div id="Parents">
        <section id="parents-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">親祭典の理</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-4-1.jpg'),
                        require('@/assets/background/background-4-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="parents-title" class="title-section-style hide-mb">
            <h2 class="title f29">親祭典の理</h2>
        </section>
        <section id="parents-main" class="w80">
            <div
                v-for="(item, index) in parentsArticle"
                class="parents-article"
                :key="'parents-article-' + index"
            >
                <aside>
                    <h3 class="f24 f22-mb">
                        <span class="month">{{ item.month }}</span
                        >月
                    </h3>
                    <h4 v-if="item.title" class="f16 f16-mb">
                        {{ item.title }}
                    </h4>
                </aside>
                <main>
                    <div
                        v-for="(subitem, subindex) of item.activities"
                        class="parents-article-article w80-mb"
                        :key="'parents-article-' + index + '-' + subindex"
                    >
                        <img v-lazy="subitem.imgUrl" alt="activity image" />
                        <article>
                            <h2 class="f29 f18-mb">{{ subitem.title }}</h2>
                            <p
                                class="f14 f12-mb"
                                v-html="wrap(subitem.article)"
                            ></p>
                        </article>
                    </div>
                </main>
            </div>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getParentsData from '@/data/getParentsData.js'
export default {
    name: 'Parents',
    setup() {
        const { parentsArticle } = getParentsData()
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            parentsArticle,
        }
    },
}
</script>
<style lang="scss" scoped>
#parents-main {
    aside {
        font-weight: 700;
        color: $majorPurple;
        background-color: $purpleC;
    }
    @media screen and (min-width: 768px) {
        .parents-article {
            display: flex;
            margin-bottom: 80px;
        }
        aside {
            flex: 0 0 50px;
            padding-top: 15px;
            text-align: center;
            border-top: 4px solid $majorPurple;
            span {
                line-height: 1em;
                display: block;
            }
            h4 {
                width: fit-content;
                margin: 10px auto;
                writing-mode: vertical-rl;
            }
        }
        main {
            .parents-article-article {
                display: flex;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            img {
                width: 30%;
                height: 20%;
                margin: 0 30px;
            }
            h2 {
                padding-bottom: 0.5em;
            }
        }
    }
    @media screen and(max-width:767px) {
        .parents-article {
            margin: 30px 0;
        }
        aside {
            height: 50px;
            padding-left: 15px;
            border-left: 4px solid $majorPurple;
            h3,
            h4 {
                display: inline-block;
                line-height: 50px;
            }
            h4 {
                margin-left: 1em;
            }
        }
        main {
            .parents-article-article {
                margin: 40px auto 50px;
            }
            img {
                width: 100%;
                height: 66.7%;
            }
            h2 {
                text-align: center;
                line-height: 3em;
            }
        }
    }
}
</style>
